import * as React from "react";
import PageNotFound from "../components/page-not-found";
import Layout from "../layout/";
const NotFoundPage = () => (
  <Layout excludeContactUs>
    <div className="flex h-[65vh] items-center justify-center p-8">
      <PageNotFound />
    </div>
  </Layout>
);

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
