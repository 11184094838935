import React from "react";
import Button from "../common/button";

const PageNotFound = () => {
  return (
    <section className="flex h-[50vh] w-full flex-col items-center justify-center gap-6 px-8 text-center">
      <h1 className="font-bold md:text-4xl">ページが見つかりません</h1>
      <div className="text-center">
        <p>指定されたURLのページは存在しません。</p>
        <p>
          サイト更新などによってURLが変更になったか、URLが正しく入力されていない可能性があります。
        </p>
      </div>

      <Button to="/">ホームページに戻る</Button>
    </section>
  );
};
export default PageNotFound;
